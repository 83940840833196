import React, {FC} from 'react';
import {Route, Switch} from "react-router-dom";
import './App.less';
import Layout from "./common/Layout";
import Profile from "./common/Profile";
import BasicPage from "./common/BasicPage";
import ClinicalTrialApiInfoEndpoints from "./components/ctgov/info/ClinicalTrialApiInfoEndpoints";
import ClinicalTrialSearch from "./components/ctgov/search/ClinicalTrialSearch";

const App: FC = () => {
  return (
      <Layout>
        <Switch>
          <Route path="/" exact component={ClinicalTrialSearch}/>
          <Route path="/info" exact component={ClinicalTrialApiInfoEndpoints}/>
          {/*<Route path="/resources" component={BasicPage}/>
          <Route path="/profile" component={Profile}/>*/}
        </Switch>
      </Layout>
  );
}

export default App;
