import React, {FC} from "react";
import {Layout as ALayout} from 'antd';
// import {withAuth0} from '@auth0/auth0-react';
import MenuBar from "./MenuBar";

const {Content, Footer} = ALayout;

interface LayoutProps {

}

const Layout: FC<LayoutProps> = ({children}) => (
  <ALayout id="site-layout">
    <MenuBar/>
    <Content id="site-content">
      {children}
    </Content>
    <Footer id="site-footer">
      A PGJF Experiment.
      &copy; 2022.
    </Footer>
  </ALayout>
)

// export default withAuth0(Layout);
export default Layout;
