import React, {FC, useEffect, useState} from 'react';

import {Col, Row, Typography, Select, Button, Alert} from "antd";
import {infoEndpoints, info, query, EndPoint} from "../../../services/clinical-trials-gov-api";
import ReactJson from 'react-json-view';


const {Option} = Select;
const {Title} = Typography;

interface ClinicalTrialSearchProps {

}

const ClinicalTrialApiInfoEndpoints: FC<ClinicalTrialSearchProps> = () => {
  const [requestType, setRequestType] = useState("dataVersion");
  const [selectedEndpoint, setSelectedEndpoint] = useState<{value: null | string,
    label: null | string,
    comment:  null | string,
    url:  null | string,
    component: null | any}>({ value: null,
    label: null,
    comment: null,
    url: null,
    component: null });
  const [apiResponse, setApiResponse] = useState({});


  const endpointChange = (value: string) => setRequestType(value);
  let resultComponent: any;
  const getInfo = async () => {
    const selected = infoEndpoints.find(x => x.value === requestType)
    if (selected) {
      const response = await info(requestType);
      setApiResponse(response);
      setSelectedEndpoint(selected)
      resultComponent = selected.component;
    }
  }

  useEffect(() => {
    document.title = "Clinical Trial Search";
  }, []);

  return (<>
    <Title>Clinical Trial Info Endpoints</Title>
    <Row>
      <Col span={24}>
        <Alert message={"These endpoints describe the data structures available at clinicaltrials.gov"} type={"info"} />
      </Col>
    </Row>

    <Row className={"m-t-10"}>
      <Col span={12}>
        <Select style={{width: 200}} onChange={endpointChange} placeholder="Select an endpoint">
          {infoEndpoints.map((x) => <Option value={x.value} key={x.value}>{x.label}</Option>)}
        </Select>
        <Button type="primary" onClick={getInfo}>Fetch</Button>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        {
          selectedEndpoint.value ? <>
              <hr />
              <h2>{selectedEndpoint.label}</h2>
              <p>{selectedEndpoint.comment}</p>
              <hr />
              <ReactJson src={apiResponse} theme={'codeschool'} />
            </>
            :
            null
        }
      </Col>
    </Row>
  </>)
}

export default ClinicalTrialApiInfoEndpoints;
