import React, {FC, useState, useEffect} from 'react';
import {Col, Row, Typography, Select, Button, Input,  Tag} from "antd";
import {countryOptions, studyStatusOptions, phaseOptions, funderTypeOptions, studyDocumentsOptions} from "./filters/FilterOptionLists";

const options = [{ value: 'gold' }, { value: 'lime' }, { value: 'green' }, { value: 'cyan' }];

const {Option} = Select;
const {Title} = Typography;

type FilterListProps = {
  onFilterChange: Function;
};

/*const tagRender = (props: any) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};*/

const FilterList: FC<FilterListProps> = ({ onFilterChange }) => {
  const [condition, setCondition] = useState("")
  const [NCTId, setNCTId] = useState("")
  const [country, setCountry] = useState<string[] | string>([])
  const [status, setStatus] = useState<string[] | string>([])
  const [phase, setPhase] = useState<string[] | string>([])
  const [funderType, setFunderType] = useState<string[] | string>([])
  const [studyDocuments, setStudyDocuments] = useState<string[] | string>([])

  useEffect(() => {
    onFilterChange({condition, NCTId, country, status, phase, funderType, studyDocuments})
  }, [condition, NCTId, country, status, funderType, studyDocuments]);

  return (
    <>
      <Input onChange={x => setNCTId(x.target.value)} placeholder="NTCId" />
      <Input onChange={x => setCondition(x.target.value)} placeholder="Condition" />
      {/*<Select
        mode="multiple"
        showArrow
        tagRender={tagRender}
        defaultValue={['gold', 'cyan']}
        style={{ width: '100%' }}
        options={options}
      />*/}
      <Select
        mode="multiple"
        showArrow
        placeholder={"Country"}
        defaultValue={country}
        onChange={value => setCountry(value)}
        style={{ width: '100%' }}
        options={countryOptions}
      />

      <Select
        mode="multiple"
        showArrow
        placeholder={"Status"}
        defaultValue={status}
        onChange={value => setStatus(value)}
        style={{ width: '100%' }}
        options={studyStatusOptions}
      />

      <Select
        mode="multiple"
        showArrow
        defaultValue={phase}
        placeholder={"Phase"}
        onChange={value => setPhase(value)}
        style={{ width: '100%' }}
        options={phaseOptions}
      />

      <Select
        mode="multiple"
        showArrow
        placeholder={"Funded By"}
        defaultValue={funderType}
        onChange={value => setFunderType(value)}
        style={{ width: '100%' }}
        options={funderTypeOptions}
      />
      <Select
        mode="multiple"
        showArrow
        placeholder={"Study Documents"}
        defaultValue={studyDocuments}
        onChange={value => setStudyDocuments(value)}
        style={{ width: '100%' }}
        options={studyDocumentsOptions}
      />

    </>
  );
};

export default FilterList;