import React, {FC} from 'react';

type ResultProps = {
  data: any;
};

const Result: FC<ResultProps> = ({data}) => {
  return (
    <>
      { data.json() }
    </>
  );
};

export default Result;