import React, {FC} from 'react';
import Result from "./Result";

type ResultListProps = {
  results: any[];
};

const ResultList: FC<ResultListProps> = ({results}) => {

  return (
    <>
      { results.map( result => <Result data={result} />)}
    </>
  );
};

export default ResultList;
