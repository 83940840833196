import axios from "axios";
import ApiDefinitions from "../components/ctgov/info/ApiDefinitions";
import CtGovResponse from "../components/ctgov/info/CtGovResponse";

const BASE_URL = "https://clinicaltrials.gov/api";

// INFO URLS
// The Study Structure, Study Fields List, Study Statistics, and Search Areas info URLs listed below retrieve key components of API Definitions."
export interface EndPoint {
  value: string,
  label: string,
  url: string,
  component: any,
  comment: string
}

export const infoEndpoints: Array<EndPoint> = [
     {
        value: "dataVersion",
        label: "Data Version",
        url: "data_vrs",
        component: CtGovResponse,
        comment: "Returns the date when the ClinicalTrials.gov dataset was posted. Although a new dataset is posted each weekday, typically only a small number of study records changes each day."
    },
     {
        value: "apiVersion",
        label: "API Version",
        url: "api_vrs",
        component: CtGovResponse,
        comment: "Returns the current version number of the ClinicalTrials.gov API. The API version number changes when the study structure changes (e.g., fields added) or when the API features change."
    },
     {
        value: "definitions",
        label: "API Definitions",
        url: "api_defs",
       component: ApiDefinitions,
       comment: "Returns detailed definitions.",
    },
     {
        value: "studyStructure",
        label: "Study Structure",
        url: "study_structure",
        component: CtGovResponse,
        comment: "Returns all available data elements for a single study record."
    },
     {
        value: "studyFields",
        label: "Study Fields List",
        url: "study_fields_list",
        component: CtGovResponse,
        comment: "Returns all data elements that can contain text values and are used by the Study Statistics and Search Areas info URLs listed below."
    },
     {
        value: "studyStats",
        label: "Study Stats",
        url: "study_statistics",
        component: CtGovResponse,
        comment: "Returns an annotated version of the Study Structure info URL."
    },
     {
        value: "searchAreas",
        label: "Search Areas",
        url: "search_areas",
        component: CtGovResponse,
        comment: ""
    }
]



export async function info(requestType: string) {
    const endpoint = infoEndpoints.find( x => x.value === requestType)?.url
    const url = `${BASE_URL}/info/${endpoint}`;
    try {
      const response = await axios.get(url, {
        params: {
          fmt: "JSON"
        }
      });
      return response.data;
    } catch (error) {
      console.error(error)
      return error;
    }
}

export function query() {
    const url = `${BASE_URL}/query`;
    console.log("query: ", url);
    return url;
}
