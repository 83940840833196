import React, {FC, useEffect, useState} from 'react';

import {Col, Row, Typography, Button} from "antd";
import {query} from "../../../services/clinical-trials-gov-api";
import ReactJson from "react-json-view";
import FilterList from "./FilterList";
import ResultList from "./ResultList";

const {Title} = Typography;

interface ClinicalTrialSearchProps {

}

const ClinicalTrialsSearch: FC<ClinicalTrialSearchProps> = () => {

  const [results, setResults] = useState<any>([]);
  const runQuery = async () => {
    const response = query();
    setResults(response);
    return response;
  }

  const onFilterChange = (data: any) => {
    console.log(data);
  }

  useEffect(() => {
    document.title = "Clinical Trial Search";
  }, []);

  return (<>
    <Title>Clinical Trial Search</Title>
    <Row>
      <Col span={12}>
        <FilterList onFilterChange={onFilterChange} />
        <Button type="primary" onClick={runQuery}>Run Query</Button>
      </Col>
    </Row>
    {results.length
      ? <>
          <Title>Results</Title>
          <Row>
            <Col>
              <ResultList results={results}/>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <ReactJson src={results} theme={'codeschool'}/>
            </Col>
          </Row>
        </>
      : null
    }
  </>)
}

export default ClinicalTrialsSearch;
