import React, {FC} from "react";
import {Link} from "react-router-dom";
import {Layout, Menu} from "antd";
// import logo from '../images/bliptick-logo-w.png';

import {
  ContainerOutlined,
  SnippetsOutlined,
  UserOutlined
} from '@ant-design/icons';


const {Header} = Layout;

interface MenuBarProps {

}

const MenuBar: FC<MenuBarProps> = () => (
  <Header id="menu-bar">
    <div className="logo">
      {/*<img src={logo} alt="SiteLogo"/>*/}
    </div>
    <Menu theme="dark" defaultSelectedKeys={['search']} mode="horizontal">
      <Menu.Item key="search" icon={<SnippetsOutlined/>}>
        <span>Search</span>
        <Link to="/"/>
      </Menu.Item>
      <Menu.Item key="info" icon={<ContainerOutlined/>}>
        <span>API Info</span>
        <Link to="/info"/>
      </Menu.Item>
      <Menu.Item key="profile" icon={<UserOutlined/>}>
        <span>Profile</span>
        <Link to="/profile"/>
      </Menu.Item>
    </Menu>
  </Header>
);


export default MenuBar;
